.bg {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: #DFF6FF;
}

.imgContainer {
    margin-bottom: 25px;
}

.textContainer {
    margin-top: 25px;
}

.line-1 {
    position: relative;
    top: 50%;
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(0, 0, 0, 0.87);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}

/* Animation */
.anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 8em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(0, 0, 0, 0.87);
    }

    to {
        border-right-color: transparent;
    }
}

@media only screen and (max-width: 425px) {
    .line-1 {
        font-size: 2em !important;
    }

    .logo {
        width: 170px;
    }
}